import React from 'react';
import Layout from '../components/layout/Layout';
import { graphql } from "gatsby";

const PrivacyPolicy = ({
  location,
}) => (
  <Layout location={location}>
    <div className="container mx-auto px-5 max-w-2xl">
      <div className="py-10 text-3xl text-center">CONDITIONS GÉNÉRALES D’UTILISATION</div>

      <div className="py-5 text-gray-600 italic">En vigueur au 15 avril 2022</div>

      <div className="py-2 text-gray-800">
        Les présentes conditions générales d’utilisation (« <b>CGU</b> ») ont pour objet l’encadrement juridique des modalités de mise à disposition du site https://cephalomax.com « <b>le Site</b> » et de définir les conditions d’accès et d’utilisation des services par <b>l’Utilisateur</b>.
      </div>
      <div className="py-2 text-gray-800">
        Elles constituent un accord légal entre l’entreprise CEPHALOMAX et l’utilisateur.
      </div>
      <div className="py-2 text-gray-800">
        En utilisant le Site vous déclarez avoir lu, compris et accepté être liée par les CGU.
      </div>
      <div className="py-2 text-gray-800">
        L’entreprise CEPHALOMAX se réserve le droit de mettre à jour ces CGU, pour les raisons incluant, mais non limitées, la conformité aux modifications des lois ou l'adéquation avec l'amélioration du Site. Si les changements ont un effet sur votre utilisation du Site ou vos droits légaux, nous vous avertirons sous sept jours avant l'implémentation de ces changements. En cas d’opposition vous ne serez plus en mesure d’utiliser le Site.
      </div>
      <div className="py-2 text-gray-800">
        L’acceptation de ces CGU vaut également acceptation de la clause de conciliation préalable (voir article 9)
      </div>

      <div className="py-5 text-xl text-primary">Article 1 : Les mentions légales</div>
      <div className="py-2 text-gray-800">
        Les mentions légales sont disponibles sur le Site https://cephalomax.com à la rubrique « mentions légales ».
      </div>

      <div className="py-5 text-xl text-primary">Article 2 : Accès au site</div>
      <div className="py-2 text-gray-800">
        Le Site https://cephalomax.com permet à l’Utilisateur un accès gratuit à l’ensemble du Site, sauf fonctionnalités soumises à un abonnement obligatoire.
      </div>
      <div className="py-2 text-gray-800">
        Le Site est accessible en tout lieu à tout Utilisateur ayant un accès à Internet.
      </div>
      <div className="py-2 text-gray-800">
        Tous les frais supportés par l’Utilisateur pour accéder au service (matériel informatique, logiciels, connexion Internet, etc.) sont à sa charge.
      </div>

      <div className="py-5 text-xl text-primary">Article 3 : Collecte des données</div>
      <div className="py-2 text-gray-800">
        Les informations concernant la collecte des données sur le Site https://cephalomax.com sont disponibles sur le Site lui-même à la rubrique « Politique de confidentialité ».
      </div>
      
      <div className="py-5 text-xl text-primary">Article 4 : Propriété intellectuelle</div>
      <div className="py-2 text-gray-800">
        Le Site Web est protégé par les droits de propriété intellectuelle et est la propriété exclusive de CEPHALOMAX. L’ensemble des éléments composant le Site (y compris : la conception, le graphisme, le contenu, l’organisation et la charte graphique) sont des œuvres originales et sont l’entière propriété de CEPHALOMAX. Toute reproduction, ou démarquage, total ou partiel, fait sans le consentement express de l’auteur est illicite. Il en est de même pour la traduction, l’adaptation, l’arrangement par quelque procédé que ce soit.
      </div>
      <div className="py-2 text-gray-800">
        L’ensemble des fonds documentaires, textes, ouvrages et illustrations mis en ligne par l’Éditeur sont protégés par le droit d’auteur et par le droit protégeant les bases de données dont l’Éditeur est producteur au sens des articles L. 341-1 et suivants du Code de la propriété intellectuelle.
      </div>
      <div className="py-2 text-gray-800">
        Le Site https://cephalomax.com autorise les autres sites, à l’exception des sites diffusant des informations et/ou contenus qui ont un caractère illégal, de créer des liens hypertextes vers son Site, sous la double réserve que le Site s’ouvre dans une nouvelle fenêtre de navigation et qu’il laisse apparaître l’adresse https://cephalomax.com.
      </div>

      <div className="py-5 text-xl text-primary">
        Article 5 : Responsabilité
      </div>
      <div className="py-2 text-gray-800">
        Les sources des informations diffusées sur le Site https://cephalomax.com sont réputées fiables. Le Site ne garantit pas qu’il soit exempt de défauts, d’erreurs ou d’omissions.
        <br/>
        Les informations communiquées sont présentées à titre indicatif et général sans valeur contractuelle. Malgré des mises à jour régulières, le Site https://cephalomax.com ne peut être tenu responsable de la modification des dispositions administratives et juridiques survenant après la publication. De même, le Site ne peut être tenu responsable de l’utilisation et de l’interprétation de l’information contenue dans ce site.
      </div>
      <div className="py-2 text-gray-800">
        Le Site https://cephalomax.com ne peut être tenu pour responsable d’éventuels virus qui pourraient infecter l’ordinateur ou tout matériel informatique de l’Internaute, à la suite d’une utilisation, à l’accès, ou au téléchargement provenant de ce Site.
      </div>
      <div className="py-2 text-gray-800">
        La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et insurmontable d’un tiers.
      </div>

      <div className="py-5 text-xl text-primary">
        Article 6 : Liens hypertextes
      </div>
      <div className="py-2 text-gray-800">
        Des liens hypertextes peuvent être présents sur le Site. L’Utilisateur est informé qu’en cliquant sur ces liens, il sortira du site https://cephalomax.com. Ce dernier n’a pas de contrôle sur les pages web sur lesquelles aboutissent ces liens et ne saurait, en aucun cas, être responsable de leur contenu.
      </div>

      <div className="py-5 text-xl text-primary">
        Article 7 : Cookies
      </div>
      <div className="py-2 text-gray-800">
        Les informations concernant la collecte des données sur le Site https://cephalomax.com sont disponibles sur le Site lui-même à la rubrique « Politique de confidentialité ».
      </div>

      <div className="py-5 text-xl text-primary">
        Article 8 : Publication par l’Utilisateur
      </div>
      <div className="py-2 text-gray-800">
        Le Site https://cephalomax.com n’autorise pas l’Utilisateur à publier sur le Site.
      </div>
      
      <div className="py-5 text-xl text-primary">
        Article 9 : Droit applicable et juridiction compétente
      </div>
      <div className="py-2 text-gray-800">
        La législation française s’applique au présent contrat.
      </div>
      <div className="py-2 text-gray-800">
      Tout différend né de l’utilisation du Site https://cephalomax.com devra faire l’objet d’une procédure de médiation préalable à toutes saisines des juridictions françaises.
      </div>
      <div className="py-2 text-gray-800">
        Afin de mener à bien cette médiation, les parties devront se mettre d’accord sur un conciliateur de justice ou sur un tiers chargé de désigner ce conciliateur de justice.  À défaut les juridictions françaises seront saisies afin de permettre la désignation de ce conciliateur.
        <br/>
        En cas d’absence de résolution amiable d’un litige né entre les parties, les tribunaux français seront seuls compétents pour en connaître.
      </div>
      <div className="py-2 text-gray-800">
        Pour toute question relative à l’application des présentes CGU, vous pouvez joindre l’éditeur aux coordonnées inscrit aux mentions légales disponibles en article 1.
      </div>
    </div>
    
  </Layout>
);

export default PrivacyPolicy;

export const query = graphql`
  query {
    locales: allLocale{
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`